import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type { ContentfulComponentForm, ContentfulComponentHeading, ContentfulTemplateTipsAndTools } from 'graphqlTypes';
import type { FC } from 'react';

interface TipstoolDataType {
  form: ContentfulComponentForm;
  tipstoolData: ContentfulTemplateTipsAndTools;
  cardsHeading: ContentfulComponentHeading;
}

const Page: FC<PageProps<TipstoolDataType>> = ({ data: { tipstoolData, cardsHeading, form }, location }) => (
  <Layout slug={location.pathname} seo={tipstoolData.seo}>
    <ResourceDetailLayout
      {...tipstoolData}
      cardsHeading={cardsHeading}
      displayBreadcrumbs={tipstoolData.seo?.displayBreadcrumbs}
      form={form}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateTipstoolQuery($slug: String!) {
    tipstoolData: contentfulTemplateTipsAndTools(slug: { eq: $slug }) {
      ...contentfulTemplateTipsAndTools
    }
    cardsHeading: contentfulComponentHeading(contentful_id: { eq: "6xNTCD1J6TJqREu5m1UrnX" }) {
      ...contentfulComponentHeading
    }
    form: contentfulComponentForm(contentful_id: { eq: "4n7LmBwc0WKJSeBRzG3APW" }) {
      ...contentfulComponentForm
    }
  }
`;
